@import "../mixins/variables";
@import "../mixins/media";
@import "../mixins/clearfix";

.ec-miscpageRole {
  @include container;
  padding: 0;
  margin-bottom: 4rem;
  @include media_desktop {
    padding: 32px 0 0;
  }

  .content-column {
      padding: 4rem 2rem;
      li {
        list-style: none;
      }
      @include media_desktop {
        padding: 0 2rem 0 1rem;
      }
  }

  .visual-column {
    .slick-slider {
      margin-bottom: 0;
    }
    @include media_desktop {
      padding: 0 2rem;
    }
  }

  & &__about {
    @include media_narrow_desktop {
      margin-bottom: calc(-50vw + 550px); 
    }

    .about-menu {
      display: none;
      @include media_desktop {
        display: flex;
        justify-content: flex-start;
        padding: 0;
        margin-bottom: 2rem;
        li {
          font-size: 1.4rem;
          margin-right: 2rem;
        }
      }
    }

    .toggle-greeting, .toggle-award {
      cursor: pointer;
      &:hover {
        color: $primary-link-color;
      }
    }
    .text-greeting, .text-award {
      margin-bottom: 6rem;

      h2 {
        font-size: 2rem;
        margin-left: -2px;
        margin-bottom: 1.8rem;
      }
      @include media_desktop {
        position: absolute;
        h2 { display: none; }
      }
      p {
        line-height: 2.8rem;
        @include media_desktop {
          line-height: 2rem;
        }
        margin-bottom: 2rem;
      }
    }
    .award-list {
      padding: 1rem;
      font-size: 1.2rem;
      li {
        margin-bottom: 1rem;
      }
    }

    .sign {
      text-align: right;
      font-size: 90%;
    }

    .text-award {
      @include media_desktop {
        display: none;
      }
    }
  }

  & &__company {
    .company-info {
      @include media_desktop {
        margin-top: 2rem;
      }
      font-size: 1.4rem;
      dd {
        margin: 0.8rem 0 1rem 1rem;
      }
      ul {
        padding-left: 0;
      }
      li {
        margin-bottom: 1.2rem;
        @include media_desktop {
          margin-bottom: 0;
        }
      }
    }
  }

  & &__telfax {
    .content-column {
      display: flex;
      flex-direction: column;
      justify-content: center;
    }
    .telfax-info {
      dl {
        margin-top: 2rem;
      }
      dd {
        margin: 1rem auto 2rem;
      }
      .available, .attention{
        font-size: 1.2rem;
      }
    }
  }

  & &__news {
    .news-list {
      margin-top: 2rem;
      padding-left: 2rem;
      @include media_desktop {
        margin-top: 0;
        padding-left: 0;
      }
    }

    @include media_desktop {
      .fixed-flex-height {
        position: relative;
      }

      .fixed-flex-height-content {
        position: absolute;
        height: 100%;
      }
      .news-list {
        overflow-y: auto;
        &::-webkit-scrollbar{
          display: none;
        }
      }
    }
    .news-item {
      margin-bottom: 2rem;
      font-size: 1.2rem;
      .news-item-title {
        font-size: 1.6rem;
        margin-top: 1rem;
        padding-left: 0.4rem;
      }
      .news-item-description {
        padding-left: 0.4rem;
      }
    }
  }

}
