@import "../mixins/media";
@import "../mixins/variables";

/*
商品詳細

商品詳細ページに関する Project コンポーネントを定義します。

ex [商品詳細ページ](http://demo3.ec-cube.net/products/detail/18)


Markup:
include /assets/tmpl/elements/14.1.product.pug
+ec-productSimpleRole

Styleguide 14.1
*/
.ec-productRole {
  padding-top: 2.4rem;
  & &__main {
    margin-bottom: 8rem;
  }

  & &__img {
    margin-right: 0;
    margin-bottom: 20px;
    @include media_desktop {
      margin-right: 16px;
      margin-bottom: 0;
    }
  }
  & &__profile {
    @include productContainer;
    margin-left: 0;
    @include media_desktop {
      margin-left: 16px;
    }
  }
  & &__title {
    margin-bottom: 2rem;
    .ec-headingTitle {
      font-size: 2.0rem;
      @include media_desktop {
        font-size: 2.4rem;
      }
    }
  }
  & &__tags {
    margin-top: 16px;
    padding: 0;
    padding-bottom: 16px;
    border-bottom: 1px solid $primary-color;
  }
  & &__tag {
    display: inline-block;
    padding: 2px 5px;
    list-style: none;
    font-size: 80%;
    color: #525263;
    border: solid 1px #D7DADD;
    border-radius: 3px;
    background-color: #F5F7F8;
  }
  & &__priceRegular {
    padding-top: 14px
  }
  & &__priceRegularTax {
    margin-left: 5px;
    font-size: 10px;
  }
  & &__price {
    color: $primary-color;
    font-size: 2.0rem;
    padding: 0;
    border-bottom: 0;
    @include media_desktop {
      padding: 14px 0;
      border-bottom: 1px solid $primary-color;
    }
  }
  & &__code {
    padding: 14px 0;
    border-bottom: 1px solid $primary-color;
  }
  & &__category {
    padding: 14px 0;
    border-bottom: 1px solid $primary-color;
    a {
      color: #33A8D0;
    }
    ul {
      list-style: none;
      padding: 0;
      margin: 0;
    }
  }
  & &__actions {
    padding: 24px 0;
    .ec-select {
      select {
        height: 40px;
        max-width: 100%;
        min-width: 100%;
        // @include media_desktop {
        //   min-width: 350px;
        //   max-width: 350px;
        // }
      }
    }
  }
  & &__btn {
    width: 100%;
    margin-bottom: 10px;
    @include media_desktop {
      width: 100%;
      margin-bottom: 16px;
      //min-width: 350px;
    }
  }
  & &__description {
    margin-bottom: 16px;
    line-height: 1.6;
    @include media_desktop {
      padding: 0 4px;
    }
    p {
      margin-bottom: 2rem;
    }
    .attention {
      color: $primary-link-color;
    }
    .appendix, .award, .best-before {
      margin-bottom: 1rem;
    }
    .award, .best-before {
      font-size: 1.2rem;
    }
    .seasonal_product {
      padding-top: 1rem;
      h3 {
        font-size: 1.8rem;
      }
      p {
        font-size: 1.4rem;
      }
      dd {
        font-size: 1.4rem;
        margin-bottom: 1rem;
        margin-left: 1rem;
      }
    }

  }
  
  & &__subdescription {
    @include productContainer;
  }
  
  & &__subdescriptionHeading {
    margin: 4rem 0 2rem;
    font-size: 1.6rem;
    @include media_desktop {
      margin-left: 20px;
    }
  } 
  & &__subdescriptionGrid {
    @include media_desktop {
      display: flex;
      flex-wrap: wrap;
    }
  }
  & &__subdescriptionGridItem {
    margin-bottom: 4rem;
    font-size: 1.2rem;
    img {
      margin-bottom: 1rem;
    }
    @include media_desktop {
      width: 50%;
      padding: 0 20px;
    }
  }

}
