@import "../mixins/media";
@import "../mixins/clearfix";

/*
カテゴリ（アイテム3列）

トップページで使用されているカテゴリのスタイルです。

ex [トップページ](http://demo3.ec-cube.net/)

Markup:
sg-wrapper:
<div class="ec-role">
  <sg-wrapper-content/>
</div>

Styleguide 12.7.1
*/

.ec-categoryRole {
  content-visibility: auto;
  //padding: 40px 0;
  color: black;
  //background: #F8F8F8;

  @include media_desktop {
    padding: 0 0 60px 0;
  }

  & &__list {
    display: flex;
    flex-wrap: wrap;

    @include media_desktop {
      flex-wrap: wrap;
    }

  }
  & &__listItem {
    margin-bottom: 20px;
    width: 50%;
    height: auto;
    
    a {
      background-color: #fff;
      height: 100%;
      display: block;
    }

    @include media_desktop {
      width: calc(100% / 3);

      &:not(:last-of-type){
        //margin-right: 30px;
      }
    }
    .figure {
      margin: 1rem 8px 4px;
      @include media_desktop {
        margin: 1rem 40px 4px
      }
    }
    .caption {
      padding: 0.5rem 8px;
      @include media_desktop {
        padding: 0.5rem 40px;
      }
    }

  }

}
