@import "./variables";

@mixin borderTop(){
  border-top: 1px dotted #ccc;
}

@mixin borderBottom(){
  border-bottom: 1px dotted #ccc;
}

@mixin reset_link(){
  a{
    color: $primary-color;
    text-decoration: none;
  }
  a:hover{
    color: $primary-link-color;
    text-decoration: none;
  }
}

.border-tamaiya-wave {
  height: 5rem;
  width: 100%;
  text-align: right;
  display: flex;
  div {
    height: 5rem;
    width: 1130px;
    background-image: url("../img/common/tamaiya_line_wave.svg");
    background-size: 1130px;
    background-position-y: center;
    background-position-x: right;
    background-repeat: no-repeat;
    overflow: hidden;
  }
  &.color-1 {
    div { background-image: url("../img/common/tamaiya_line_wave_color1.svg"); }
  }
  &.color-2 {
    div { background-image: url("../img/common/tamaiya_line_wave_color2.svg"); }
  }
  &.color-3 {
    div { background-image: url("../img/common/tamaiya_line_wave_color3.svg"); }
  }
  &.color-4 {
    div { background-image: url("../img/common/tamaiya_line_wave_color4.svg"); }
  }
  &.color-5 {
    div { background-image: url("../img/common/tamaiya_line_wave_color5.svg"); }
  }
  &.color-6 {
    div { background-image: url("../img/common/tamaiya_line_wave_color6.svg"); }
  }
}