
// MediaQuery
$tablet: 480px;
$desktop: 768px;
$desktop2: 992px;
$desktop3: 1200px;

$font-size: 16px;
$primary-color: #000;
$primary-link-color: #b30000;
$primary-blue-color: #3f79aa;

$clrRed: #DE5D50;
$clrRich: #9a947e;
$clrGray: #F3F3F3;
$clrRightGray: #B8BEC4;
$clrExtraGray: #636378;
$clrDarkGray:#525263;
@import "../../../../../../node_modules/bootstrap-sass/assets/stylesheets/bootstrap/variables";
