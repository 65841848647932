@import "./variables";
@import "../../../../../../node_modules/bootstrap-sass/assets/stylesheets/bootstrap/mixins/forms";
@import "../../../../../../node_modules/bootstrap-sass/assets/stylesheets/bootstrap/mixins/tab-focus";
@import "../../../../../../node_modules/bootstrap-sass/assets/stylesheets/bootstrap/mixins/vendor-prefixes";
@import "../../../../../../node_modules/bootstrap-sass/assets/stylesheets/bootstrap/mixins/tab-focus";


@keyframes fadeIn{
  0%{
    opacity: 0;
    visibility: hidden;
  }
  100%{
    opacity: 1;
    visibility: visible;
  }
}

@keyframes fadeOut{
  0%{
    opacity: 1;
    visibility: visible;
  }
  100%{
    opacity: 0;
    visibility: hidden;
  }
}

@mixin fadeIn($display:block,$time:150ms) {
  display: $display;
  opacity: 1;
  visibility: visible;
  animation: fadeIn $time linear 0s;
}
@mixin fadeOut($time:150ms) {
  opacity: 0;
  visibility:hidden;
  animation: fadeOut $time linear 0s;
}

.bg-load-overlay {
  background: rgba(255, 255, 255, 0.4);
  box-sizing: border-box;
  position: fixed;
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: space-around;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 2147483647;
  opacity: 1;
}

.page-loader {
  box-sizing: border-box;
  position: fixed;
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: space-around;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: 2147483647;
  opacity: 1;
  background:url('/html/template/default/assets/img/top/bg_loading.jpg');
  background-size: cover;
  background-position: center;

  &.disabled {
    display: none;
  }
  
  &:not(.is-active) {
    @include fadeOut(500ms);
  }

  .title {
    width: 100%;
    height: 100%;
    z-index: 65537;
    background:url('/html/template/default/assets/img/top/logo_loading.png') no-repeat;
    background-size: cover;
    background-position: center;
  }

//SP
  // .title {
  //   width: 100%;
  //   height: 100%;
  //   z-index: 65537;
  //   background:url('/html/template/default/assets/img/top/logo_loading.png') no-repeat;
  //   background-size: contain;
  //   background-position: center;
  // }
}

body.loading {
  overflow: hidden;
}