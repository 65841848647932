@import "../mixins/media";

/*
アイキャッチ

トップページ アイキャッチ部に関する Project コンポーネントを定義します。

ex [トップページスライダー直下 アイキャッチ部](http://demo3.ec-cube.net/)

Markup:
include /assets/tmpl/elements/12.2.eyecatch.pug
+ec-eyecatchRole

Styleguide 12.2
*/
.ec-eyecatchRole {
  display: flex;
  flex-wrap: wrap;
  // margin-bottom: 40px;

  @include media_desktop {
    flex-wrap: nowrap;
  }

  & &__image {
    display: block;
    margin-bottom: 40px;
    width: 100%;
    height: 100%;

    @include media_desktop {
      order: 2;
    }
  }

  & &__intro {
    color: black;

    @include media_desktop {
      padding-right: 5%;
      order: 1;
    }
  }
  & &__introEnTitle {
    margin-bottom: .8em;
    font-size: 16px;
    font-weight: normal;

    @include media_desktop {
      margin-top: 45px;
    }
  }
  & &__introTitle {
    margin-bottom: .8em;
    font-size: 24px;
    font-weight: bold;

    @include media_desktop {
      margin-bottom: 1em;
      font-size: 26px;
    }
  }
  /* Deprecated https://github.com/EC-CUBE/ec-cube/pull/4220 */
  & &__introDescriptiron {
    margin-bottom: 20px;
    font-size: 16px;
    line-height: 2;
    @include media_desktop {
      margin-bottom: 30px;
    }
  }
  & &__introDescription {
    margin-bottom: 20px;
    font-size: 16px;
    line-height: 2;
    @include media_desktop {
      margin-bottom: 30px;
    }
  }
}
